/**
 * import
 *
 * @version 0.1 (2021.05.13, 05.14 : usayama)
 * @version 0.2 (2021.06.23 : usayama) // terms 追加
 * @version 0.3 (2021.06.28 : usayama) // Seo 追加
 */
import React from 'react'
// import { graphql } from 'gatsby'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
// import i18n from 'i18next'
// conf, action
import termsActions from '@redux/terms/actions'
// components
import Seo from '@components/Tools/Seo'
// containers
import LayoutContainer from '@containers/LayoutContainer'
import TextBasePageContainer from '@containers/TextBasePageContainer'
// helper
// style, img, elm
// const
const { getTerms } = termsActions

/**
 * const
 *
 * @version 0.1 (2021.05.13, 05.14 : usayama)
 * @version 0.2 (2021.06.23 : usayama) // function に変更
 */
// const Terms = ({data}) => {
function Terms(props) {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.05.18 : usayama_dx)
   * @version 0.2 (2021.06.28 : usayama) // transrate 追加
   */
  // const { ...attrs } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { terms } = useSelector(state => state.Terms)

  /** 
   * state : 
   * 
   * @version 0.1 (2021.05.18 : usayama)
  */
  const [initPage, setInitPage] = React.useState(false)

  /** 
   * state : 
   * 
   * @version 0.1 (2021.06.23 : usayama)
   */
  React.useEffect(() => {
    if(!initPage) {
      dispatch(getTerms())
      setInitPage(true)
    }
  }, [initPage, dispatch])

  /** 
   * return : 
   * 
 * @version 0.1 (2021.05.13, 05.14 : usayama)
 * @version 0.2 (2021.06.23 : usayama) // terms に変更
 * @version 0.3 (2021.06.28 : usayama) // Seo 追加
   */
	return  <LayoutContainer>
        <Seo title={t('title.terms')} />
  			<TextBasePageContainer className="terms" title="terms">
  				{ terms && <div dangerouslySetInnerHTML={{ __html: terms.text }} />}
  			</TextBasePageContainer>
			</LayoutContainer>
}

/**
 * export
 *
 * @version 0.1 (2021.05.13, 05.14 : usayama)
 */
export default Terms

/**
 * export
 *
 * @version 0.1 (2021.05.14 : usayama)
 */
/*
export const query = graphql`
	query TermsQuery {
	  allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(\/ja\/terms)/"}}) {
	    edges {
	      node {
	        id
	        html
	      }
	    }
	  }
	}
`
*/
/*
: todo
export const query = graphql`
	query MyQuery {
	  allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/${escapeStringRegexp(pagePath)}/"}}) {
	    edges {
	      node {
	        id
	        html
	        fileAbsolutePath
	      }
	    }
	  }
	}
`
*/